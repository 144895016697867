<template>
  <!-- <div> -->

    <!-- -------------------------------------- START OF VUELAYERS ----------------------------------------------------------- -->
      <!-- <div class="mt-n3 mx-n3 mb-n3" style="min-heightt: calc(100vh - 54px); min-heightt: -webkit-fill-available;"> -->
      <!-- <div class="mt-n0 mx-n0 mb-n0">             -->
        <!-- data-projection="EPSG:3857" style="height: calc(100vh - 54px);">   -->
        <vl-map ref="map" :load-tiles-while-animating="true" class="main_map" :load-tiles-while-interacting="true"  @click="mapClick"
                data-projection="EPSG:3857">    
          <vl-view ref="mapView" :zoom.sync="zoom" :center.sync="center" :enable-rotation=false :rotation.sync="rotation" :maxZoom=16></vl-view>

          <!-- <template> -->
              <!-- USES THE ACCURACY TO DETERMINE RADIUS? -->
              <!-- TRACKING DOT -->
              <!-- [-12746726.903598461, 7285710.65473805] -->
              <!-- [-116.272442,53.795582] -->
          <vl-feature v-if="gps2 && geoloc2Position" id="position-feature" :zIndex=99>

            <vl-geom-point :coordinates="geoloc2Position"></vl-geom-point>   
                      
              <vl-style-box :zIndex=98>
                
                <vl-style-circle :radius= '4'>
                      
                <vl-style-fill color="red"></vl-style-fill>
                <vl-style-stroke color="red"></vl-style-stroke>
                </vl-style-circle>
              </vl-style-box>
          </vl-feature>

          <!-- ACCURACY CIRCLE IF IT IS POOR -->
          <vl-feature v-if="gps2 && geoloc2Accuracy > 50" id="accuracy-feature" :zIndex=99>
            <vl-geom-circle :coordinates="geoloc2Position" :radius="geoloc2Accuracy"></vl-geom-circle>                
              <vl-style-box :zIndex=99>
                <vl-style-fill color="rgb(255, 10, 10,0.2)"></vl-style-fill>
                <vl-style-stroke color="rgb(255, 10, 10,0.3)"></vl-style-stroke>
              </vl-style-box>
          </vl-feature>
          <!-- </template> -->


          <!-- GEOMAPPING*** -->
          <vl-feature>
            <vl-geom-line-string v-if=geoMapping.length :coordinates="geoMapping"></vl-geom-line-string>
          </vl-feature>

          
          <!-- TESTING GEOMPOINT -->
          <vl-feature v-if="location.length" id="my-position-feature" :zIndex=99>
            <!-- USES THE ACCURACY TO DETERMINE RADIUS? -->

            <!-- TRACKING DOT -->
            <vl-geom-point :coordinates="location"></vl-geom-point>                
              <vl-style-box :zIndex=98>
                <vl-style-circle :radius= '40'>
                <vl-style-fill color="blue"></vl-style-fill>
                <vl-style-stroke color="red"></vl-style-stroke>
                </vl-style-circle>
              </vl-style-box>
          </vl-feature>

          <vl-layer-tile id="osm">
            <vl-source-osm :url=mapURL></vl-source-osm>
          </vl-layer-tile>

          <!-- ALERT*** -->
          <v-alert dense transition="slide-y-transition" :type=flashMessage.type  :value="showAlert" class='font-weight-medium map_alert'>       
              <!-- <div v-for="validator in validators" :key=validator.param> -->

            <span>{{flashMessage.msg}}</span>
            <v-btn class='mt-n2' icon v-on:click='closeAlert'><v-icon class='mt-1'>mdi-close-circle-outline</v-icon></v-btn>    
              <!-- </div> -->
          </v-alert>  

          <v-alert dismissiblee dense  transition="slide-y-transition" :type=geoloc2Error.type  :value="showGeoAlert" class='font-weight-medium map_alert'>       
              <!-- <div v-for="validator in validators" :key=validator.param> -->
                  {{geoloc2Error.msg}}
              <!-- </div> -->
          </v-alert>          

          <!-- HERE BE PIPELINE BUTTONS -->
          <div style="z-index:1" class="mapinfo ol-unselectable ol-control"><button title='Extra Options' v-on:click="cardExpand" @click="expand = !expand"><v-icon  class="white--text">mdi-information</v-icon></button></div>

          <!-- LOCATION BUTTON -->
          <!-- "gps2 = !gps2; gps2 ? geoWatchStart() : geoWatchStop()" -->
          <!-- <div style="z-index:1; top: 39px"  class="mapinfo ol-unselectable ol-control"  ><button title='Enable GPS' @click="gps = !gps"><v-icon   class='white--text' :class="{'yellow--text': gps}">mdi-crosshairs-gps</v-icon></button></div> -->
          <div style="z-index:1; top: 39px"  class="mapinfo ol-unselectable ol-control"  ><button title='Enable GPS' @click="gps2 = !gps2; gps2 ? geoWatchStart() : geoWatchStop()"><v-icon   class='white--text' :class="{'yellow--text': gps2}">mdi-crosshairs-gps</v-icon></button></div>

          <!-- FIND BY CLICK -->
          <div style="z-index:1; top: 71px"  class="mapinfo ol-unselectable ol-control"  >
            <button title='Search By Clicking' @click="enableCoordinateClick = !enableCoordinateClick" >
              <v-icon class='white--text' :class="{'yellow--text': enableCoordinateClick}">mdi-cursor-default-outline</v-icon>
            </button>
          </div>

          <v-expand-transition>
              <v-card  width="210" id='mapcard2'  v-show="expand" class="" tile >
                <v-tabs  dark grow background-color="#7b98bd" color="blue-grey darken-2" ref="tabs">
                  <v-tabs-slider color="blue-grey darken-2"></v-tabs-slider>
                  <v-tab  ><v-icon >mdi-eye-outline</v-icon></v-tab> 
                  <v-tab  ><v-icon >mdi-map-legend</v-icon></v-tab>     
                  <v-tab-item class="pt-0 px-4 ">
                    <div>

                      <!-- BELOW IS FOR THE SWITCHES -->
                      <v-switch dense color="#7b98bd" v-model="pipelineSwitch" :loading=pipelinesLoading label="Pipelines"></v-switch>
                      <v-switch dense color="#7b98bd" v-model="bingSwitch" class="my-n6" label="Bing Maps"></v-switch> 
                      <v-switch dense color="#7b98bd" :input-value="viewNoneOperatedWellsFlag" @change="viewNoneOperatedWells()" class="my-n6" v-slot:label>
                        <template >
                          <span >Hidden Wells</span>

                          <v-icon v-on:click.stop.prevent @click = "showHiddenInfo = !showHiddenInfo">mdi-information-outline</v-icon>
                            <v-tooltip bottom v-model="showHiddenInfo" >
                              <!-- <template v-slot:activator="{ on, attrs }" v-on:click="exitt()"> -->
                                <template v-slot:activator="{ on }" >
                                <span></span>
                              </template>
                              <!-- within tooltip we need to use style='pointer-evens: initial' so we can click on these elements -->
                              <span>Wells will be hidden for a variaty of reasons </span> 
                              <span class="default_cursor" v-on:click="showHiddenInfo = !showHiddenInfo" style="pointer-events: initial;"> [X]</span>
                              <ul >
                              
                                <li>Abandoned or Suspended</li>
                                <li>Well has been re-entered</li>
                                <li>If the well is very new</li>
                              </ul>
                            </v-tooltip>
                          </template>
                      </v-switch>   
                      <v-switch disabled dense color="#7b98bd" v-model="switch3" class="my-0" label="TWP Grid"></v-switch>
                      <!-- ABOVE IS FOR THE SWITCHES -->
                    </div>
                  
                  </v-tab-item>
                  <v-tab-item>
                    <div class="pt-1 px-4">
                      <v-list dense>
                        <v-list-item light shaped subheader>
                          <v-icon style="color: rgba(0,212,240,0.5)">mdi-texture-box</v-icon><v-subheader>4" Pipeline</v-subheader>
                        </v-list-item>
                        <v-list-item>
                          <v-icon style="color: rgba(221,146,7,0.5)">mdi-texture-box</v-icon><v-subheader>6" Pipeline</v-subheader>
                        </v-list-item>
                        <v-list-item>
                          <v-icon style="color: rgba(0,109,45,0.5)">mdi-texture-box</v-icon><v-subheader>8" Pipeline</v-subheader>
                        </v-list-item>
                        <v-list-item>
                          <v-icon style="color: rgba(0,252,105,0.5)">mdi-texture-box</v-icon><v-subheader>10" Pipeline</v-subheader>
                        </v-list-item>           
                      </v-list>
                    </div>
                  </v-tab-item>

                </v-tabs>
              </v-card>
          </v-expand-transition>

          <!-- BING MAPS -->
          <vl-layer-tile id="bingmaps" :visible=bingVisibility >
              <vl-source-bingmaps :api-key="apiKey" :imagery-set="imagerySet" ></vl-source-bingmaps>
          </vl-layer-tile>

          <!-- GOOGLE MAPS -->
          <vl-layer-tile :visible=false>
            <vl-source-xyz
              attributions='<a href="https://www.google.at/permissions/geoguidelines/attr-guide.html">Map data ©2015 Google</a>'
              url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            >
            </vl-source-xyz>
          </vl-layer-tile>          

          <!-- PIPELINE RENDER FROM VUELAYERS -->
          <!-- !!!!!!!!!!! -->
          <vl-layer-vector id="pipelines" :zIndex=10 :update-while-interacting=true :visible=pipelineSwitch  v-if="pipelineLayer">
            <vl-source-vector :features.sync="pipelineLayer.features"></vl-source-vector>
            <vl-style-func :factory='pipelineStyles'></vl-style-func>
          </vl-layer-vector>

          <!--!!! PIN DROPS FROM VUELAYERS -->
          <vl-layer-vector id="pins" :zIndex=15 :update-while-interacting=true :visible=true v-if="pinObject">
            <vl-source-vector :features.sync="pinObject.features"></vl-source-vector>
            <vl-style-func :factory='pinStyleFunction'></vl-style-func>
          </vl-layer-vector>

          <!-- shows pins that have no operator attached -->
          <vl-layer-vector id="hidden_pins" :zIndex=15 :update-while-interacting=true :visible=viewNoneOperatedWellsFlag v-if="hiddenPinObject">
          <!-- <vl-layer-vector id="hidden_pins" :zIndex=15 :update-while-interacting=true :visible=hiddenPins v-if="hiddenPinObject">   -->
            <vl-source-vector :features.sync="hiddenPinObject.features"></vl-source-vector>
            <vl-source-vector :features.sync="hiddenPinObject.features"></vl-source-vector>
            <vl-style-func :factory='pinStyleFunction'></vl-style-func>
          </vl-layer-vector>          

          <vl-layer-vector id="pins_by_click" :zIndex=15 :update-while-interacting=true :visible=true v-if="coordinateObject">
            <vl-source-vector :features.sync="coordinateObject.features"></vl-source-vector>
            <vl-style-func :factory='pinStyleFunction'></vl-style-func>
          </vl-layer-vector>          

          <!-- DETAILED WELL OR PIPELINE POPUP -->
          <div id="detailedPopUp" class="ol_box" v-if="detailedPopupArray" style='position: absolute; bottom: 20px; right: -48px; width: 15em;' :style='{"z-index": detailedZ}'>
            <div v-if="detailedPopupArray.uwi">
              <p style="margin: 0;">UWI: {{detailedPopupArray.uwi}}</p>
              <p style="margin: 0;">LSD: {{detailedPopupArray.surfloc}}</p>
              <p style="margin: 0;">COMPLETE: {{detailedPopupArray.complete}}</p>
              <p style="margin: 0; overflow-wrap: break-word;" >DESCRIPTION: {{detailedPopupArray.description}}</p>
            </div>
            <div v-if="!detailedPopupArray.uwi">

              <p style="margin: 0;">From: {{detailedPopupArray.from_loc}}</p>
              <p style="margin: 0;">To: {{detailedPopupArray.to_loc}}</p>
              <p style="margin: 0;">Licence: {{detailedPopupArray.licence_no}}</p>
              <p style="margin: 0;">Licence LI NO: {{detailedPopupArray.lic_li_no}}</p>
              <p style="margin: 0;">Length: {{detailedPopupArray.seg_length}}</p>
              <p style="margin: 0;">Status: {{detailedPopupArray.seg_status}}</p>
              <p style="margin: 0;">Outer Diameter: {{detailedPopupArray.out_diamet}}</p>

            </div>
          </div>
            
          <!-- PIPELINE POPUP ON CLICK   !!!!--> 
          <!-- <vl-overlay id="pipelineOverlay" :position="pipelineOverlayCoordinate"  :style="{display: pipelineOverlay2}" v-if='pipelineOverlayVisiblity'>  
            <template slot-scope="scope">
              <div class="ol_box" v-for="(pipeline, indexx) in pipelineArray" :key="indexx" v-on:click="popUpClick(pipeline)" >
                
                <v-icon class="white--text" v-on:click="popUpHover(pipeline)">mdi-eye-circle-outline</v-icon>
                Licencexxx: {{pipeline.lic_li_no}}
              </div>
            </template>
          </vl-overlay> -->

          <!-- data projection = EPSG:4326', 'EPSG:3857 -->
          <!-- WELL POPUP ON CLICK AND PIPELINE PINPOPUPARRAY-->
          <!-- !!! REMOVED PINPOPUP LENGTH RESTRICTIN -->
          <!-- v-iff='pinPopUp.length > 0' -->
          <vl-overlay id="pinOverlay" :position="overlayCoordinate" :style="{display: pinOverlay}" data-projection='point' >
            <template slot-scope="scope">
              <!-- WHITE-SPACE NOWRAP STOP THE TEXT FROM WRAPING WHEN IT HITS THE RIGHT SIDE OF THE SCREEN -->
              <div class="ol_box" style="white-space: nowrap;" v-for="(uwi, index) in pinPopUp" :key="index" v-on:click="popUpClick(uwi)">
                UWIxxx: {{uwi.uwi}}
                <!-- scopt: {{scope}} -->
              </div>
            </template>
          </vl-overlay>
        </vl-map>
        
</template>

<script>

  import VueLayers from 'vuelayers'
  import 'vuelayers/lib/style.css' // needs css-loader
  import Vue from 'vue'
  import {Vector as VectorLayer} from 'ol/layer';
  import {transform} from 'ol/proj';
  import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
  import VectorSource from 'ol/source/Vector';
  import GeoJSON from 'ol/format/GeoJSON';
  import axios from 'axios';

  Vue.use(VueLayers, {
    // dataProjection: 'EPSG:4326',
  })
  export default {
    name: "map-openlayers",
    data(){
      return{
        // test
        // myVar: setInterval(this.test, 10000),
        // transform([-114.505796,54.605685],'EPSG:4326', 'EPSG:3857'),
        geoMapping: [],
        showGeoAlert: false,
        interval: 0,
        // testModel: 3,
        // testData: [[-116.272442,53.795582],[ -116.09098519996284,53.86656160683027]],
        // VUE GEOLOCATION TEST
        location:[],
        gettingLocation: false,
        errorStr:null,        

        // NO WELLS FOUND ON CLICK ALERT
        showAlert: false,



        // flashMessage: {msg: 'No Wells Found', type: 'error'},
        flashMessage: {},
        pipelinesLoading: false,
        // bingLoading: false,
        // hiddenWellsInfo: false,
        // dialog: false,
        showHiddenInfo: false,
        // this should be from the store
        hiddenPins: false,
        // TRACKING OPTION FOR GEOLOCATION SERVICES
        tracking_options: {
          'enableHighAccuracy': true, 
          'maximumAge': 1000,
          'timeout': 5000,
        },
        geolocAccuracy: undefined,
        geolocPosition: undefined,

        geoloc2Accuracy: undefined,
        geoloc2Position: undefined,
        geoloc2Error: {msg: 'No Wells Found2', type: 'error'},


        gps2: false,
        // geolocPosition3857: transform([-12746726.903598461, 7285710.65473805], 'EPSG:3857', 'EPSG:4326'),
        // epsg:3857 = the very long numbers
        // epsg:4326 = latitud , longitude
        // -12746726.903598461, 7285710.65473805
        // transform([-114.505796,54.605685],'EPSG:4326', 'EPSG:3857'),
        gps: false,
        mapURL: process.env.VUE_APP_EX_IP +'/tiles/{z}/{x}/{y}.png',

        // BING MAPS
        apiKey: 'ApgQBM7Kq9gneq3lDiwYUTKoX89x667XncsW6aF4zYlKTgvYZoiQWzLQX4gTfRuC',
        imagerySet: 'AerialWithLabels',
        bingVisibility: false,
    
        // OTHER
        clickStyle: null,
        pipelineOverlayVisiblity: false,
        detailedZ: 99,
        pinOverlay: 'none',
        pipelineOverlay2: 'none', 
        pinPopUp: [],
        x: null,

        //array of values_ from the pipeline or pin object
        pipelineArray: [],
        uwiArray: [],
        coordinateObject: null,
        enableCoordinateClick: false,
        pinObject: null,
        hiddenPinObject: null,
        pipelineOverlayCoordinate: [0,0],
        overlayCoordinate: [0,0],

        //OPEN LAYERS
        center: transform([-114.505796,54.605685],'EPSG:4326', 'EPSG:3857'),
        rotation: 0,
        zoom: 5,

        // geolocPosition: undefined,
        pipelineLayer: null,
        // todo add all fields in a proper pop over
        expanded: {
          uwi: 'n',
          surfloc: 'n',
          complete: 'n',
          description: 'n'
        },
        detailedPopupArray: null,
        // newWellPopup:{},

        //INFORMATION BUTTON
        expand: false, 
        pipelineSwitch: false, //SHOW PIPELINES
        bingSwitch: false,     //SHOW BING
        switch3: false,        //TWP GRID

        //ARRAY OF WHAT IS SHOWN ON THE MAP
        pinArray: [],  
        newPipelines: {},
      
      }
    },
    computed: {
      viewNoneOperatedWellsFlag(){
        return this.$store.state.viewNoneOperatedWells;
      },          

      simpleMultipleUWIResults(){
        return this.$store.getters.simpleMultipleUWIResults;
      },
    //   geoWatch(){
    //     let my_options = {
    //       enableHighAccuracy: true, 
    //       // 'maximumAge': 1000,
    //       // 'timeout': 5000,
    //     }      
    //     // let x = navigator.geolocation.getCurrentPosition((position) => {
    //     // })
    //     // () => {}
    //     // ,error, {enableHighAccuracy: true}
    //     return x;
    //   }
    },
        // ,err, tracking_options)

    watch: {
      pipelineSwitch(newValue){
        if(!this.pipelineLayer){
          this.getPipelines();
        }
      },
      bingSwitch(newValue){
        // this.bing.setVisible(!this.bing.getVisible())
        this.bingVisibility = !this.bingVisibility

      }
    },
    props: {
      wells: Array
    },
    components: {

    },
    methods: {
      closeAlert(){
        this.showAlert = false
      },

      geolocPosition3857(location){
        // EPSG:4326 EXAMPLE -117,54
        // EPSG:3857 EXAMPLE 123443218812.213.42.34, 2348382818234.322323
         let GPS3857 = transform(location,'EPSG:4326','EPSG:3857')
         //return GPS3857
         this.location = GPS3857

      },
      geoWatchStop(){
        // this.test();
        // clearInterval(myVar);
        clearInterval(this.interval);
      },
      geoWatchStart(){
        let pos = (position) => {
          // this.geoMapping.push([position.coords.longitude,position.coords.latitude])
          this.geoloc2Position = transform([position.coords.longitude,position.coords.latitude],'EPSG:4326', 'EPSG:3857')          
          this.geoloc2Accuracy = position.coords.accuracy
          return this.geoloc2Accuracy
        }

        let err = (error) => {
          // code: 1, message: "User denied geolocation prompt" 
          this.geoloc2Error = {msg: error.message, type:'error'}
          this.geoWatchStop();
          this.showGeoAlert = true
              // this.showAlert = false
          setTimeout(() => {
            this.showGeoAlert = false
          }, 1000)
          this.gps2 = false
        }
        let tracking = {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
        // let findCenter = () => {
        //   navigator.geolocation.getCurrentPosition(pos, err, tracking)
          
        // } 
        // findCenter();

        // this.center = 
        
        let getPosition = () => {
          // getCurrentPosition
          // watchPosition
          navigator.geolocation.getCurrentPosition(pos, err, tracking)
        }  
  	    // data:application/json,{"location": {"lat": 40.7590, "lng": -73.9845}, "accuracy": 27000.0}
        this.interval = setInterval(() => {
          getPosition();
          
        },5000)          
        // }
      },
      // STOP GPS WATCH

      // method testing end
      viewNoneOperatedWells(){
        this.$store.commit("viewNoneOperatedWells")
      },      
      // if we don't call callslider the bar indicating the selected tab won't show up
      cardExpand: function(){
        setTimeout(() => {
          this.$refs.tabs.callSlider()
        }),300
      },

      pipelineArrayFunction: function(pipeline){
      },
      // popUpHover: function(pipeline){
      //   this.$refs.map.getLayerById('pipelines').getSource().getFeatureById(pipeline.feature_id).setStyle(new Style({

      //       stroke: new Stroke({
      //         color: 'rgba(109,23,51,0.5)',
      //         width: 10.5
      //         // stroke: StrokeStyle({
      //         // color: 'rgba(109,23,51,0.5)',
      //         // width: 10.5
      //         // 2.5
      //         //crossOrigin: 'anonymous',
      //         //src: 'data/square.svg'
      //       })
      //   }));        
      // },
      // THIS IS THE DETAILED POP UP BOX
      popUpClick: function(uwi){
        this.detailedZ = 99;
        this.detailedPopupArray = uwi
        if(uwi.feature_id){
          this.$refs.map.getLayerById('pipelines').getSource().getFeatureById(uwi.feature_id).setStyle(new Style({
              stroke: new Stroke({
                color: 'rgba(109,23,51,0.5)',
                width: 10.5
              })
          }));  
        }
      },

      pinStyleFunction: function(type){
        
        return (feature, resolution) =>{
          let pinStyle = []
          if(feature.get('operator') === null){  
            pinStyle =  [new Style({
              image: new CircleStyle({
                radius: 3,
                // zIndex: 999,
                stroke: new Stroke({
                  color: "#800000",
                  width: 2,
                  
                }),
                fill: new Fill ({color: "#e08282"})

            })})]
          } else {
            pinStyle =  [new Style({
              image: new CircleStyle({
                radius: 4,
                stroke: new Stroke({
                  color: "orange",
                  width: 2, 
                }),
                fill: new Fill ({color: "#f2dbb1"})

            })})]
          }
          return pinStyle;
        }
      },
      //RENAME PIPELINSTYLEFUNCTION
      pipelineStyles: function() {
        // cache to allow styles reusing for features with same state
        // let cache = {}
        let width = 2.0

        return (feature, resolution) => {
          // let size = feature.get('out_diamet')
          let pipelineStyles = []
          // let try1 = true
          // build styles based on feature state
          if(feature.get('out_diamet') == 114.3){ //4"
            pipelineStyles = [
              //blueStroke
              new Style({
                // fill: new Fill(fill),
                stroke: new Stroke({
                  color: 'rgba(0,212,240,0.5)',
                  width: width
                }),
              })
            ]    
          } else if(feature.get('out_diamet') == "168.3"){ //6"
            pipelineStyles = [
              //blueStroke
              new Style({
                // fill: new Fill(fill),
                stroke: new Stroke({
                  color: 'rgba(221,146,7,0.5)',
                  width: width
                }),
              })
            ]    
          } else if(feature.get('out_diamet') == "219.1"){ //8"
            pipelineStyles = [
              //blueStroke
              new Style({
                // fill: new Fill(fill),
                stroke: new Stroke({
                  color: 'rgba(0,109,45,0.5)',
                  width: width
                }),
              })
            ]    
          } else if(feature.get('out_diamet') == "273.1"){ //10"
            pipelineStyles = [
              //blueStroke
              new Style({
                // fill: new Fill(fill),
                stroke: new Stroke({
                  color: 'rgba(0,252,105,0.5)',
                  width: width
                }), 
              })
            ]    
          } else {
            pipelineStyles = [
              //blueStroke
              new Style({
                // fill: new Fill(fill),
                stroke: new Stroke({
                  color: 'rgba(109,23,51,0.5)',
                  width: width
                })
              })
            ]
          }
          // style function always return array of Style instances
          return pipelineStyles 
        }
      },
    
      mapClick: function(evt) {
        // GET INFORMATION POPUP
        if(!this.enableCoordinateClick){
          // console.log('this gets info right?')
          this.detailedZ = 0
          let pipelineObject = null
          // let index = 0
          // let pixel = evt.pixel
          this.uwiArray = []
          this.pinPopUp = []
          //!! this.pipelineArray = [] 
          evt.map.forEachFeatureAtPixel(evt.pixel, (feature,layer) => {
            //!this.pipelineArray.length , once you have a pipeline in the array , pins will get ignored and vice a versa, so you cannot have both on the screen at the same time
            // first come first serve
            if(layer.get('id') == 'pins' || 'pins_by_click' && !this.pipelineArray.length){
              // THIS ALWAYS GETS CALLED PIN OR PIPELINE OR BOTH
              this.overlayCoordinate = evt.map.getCoordinateFromPixel(evt.pixel)

              
              // PINPOPUP IS USED IN PINOVERLAY COMPONENT

              // OBJECT.ASSIGN ALLOWS US TO ASSIGN ALL GETPROPERTIES() AND EXCLUDE THE GEOMETRY
              this.pinPopUp.push(Object.assign({}, feature.getProperties(), {geometry: undefined}))

              // NEED THIS FOR NOW, THIS PREVENTS MAP MOVE GLITCH
              // SHOW OR HIDE PIN OVERLAY COMPONENT
              if(this.pinOverlay == 'none'){
                  // console.log('if pin overlay is none')
                  setTimeout(() => {
                    this.pinOverlay = 'inline'
                },2)
              }
            } else if (layer.get('id') == 'pipelines' && !this.pinPopUp.length){
              console.log(' id equals pipelines and no pinpopup length')
              //  NEED TO GET THE LAYER -> SOURCE -> FEATURE


              //THIS WORKS BUT NEED TO BE ABLE TO UNSELECT FEATURE
              // this.selectedPipelineFeature = feature
              // feature.setStyle(new Style({
              //   stroke: new Stroke({
              //     color: 'rgba(109,23,51,0.5)',
              //     width: 7.5
                  // 2.5
                  //crossOrigin: 'anonymous',
                  //src: 'data/square.svg'
              //   })
              // }));  
              // !!!XXX was 3000
              // setTimeout(()=>{
              //   feature.setStyle(null)
              // },300)


              // !!! REDUCED TIMEOUT FROM 1500 TO 500
              this.pinPopUp = []

              // WITHOUT THIS , THE INITIAL POPUP IS RENDERED AT THE TOP THEN MOVED
              // if(this.pipelineOverlay2 == 'none'){
                
              //   setTimeout(() => {
              //     this.pipelineOverlay2 = 'inline'
              //   },500)
              // }
              this.pipelineOverlayCoordinate = evt.map.getCoordinateFromPixel(evt.pixel)
              // pipelineObject = feature.getId()

              // BUILE AN OBJECT THAT HAS THE VALUES OF THE FEATURE AS WELL AS THE FEATURES ID THEN PUSH THIS TO AN ARRAY
              pipelineObject = feature.values_
              // NEVER GETS CALLED
              // console.log('pipeline object =', pipelineObject)
              pipelineObject.feature_id = feature.getId()
              // this.pipelineArray.push(feature.values_)
              this.pipelineArray.push(pipelineObject)


              //NEED THIS SO THE POPUP ISN'T SHOWN ON THE BOTTOM OF THE SCREEN , GLITCH
              this.pipelineOverlayVisiblity = true
            } 
          },{hitTolerance: 5})
        // GET WELLS VIA CLICK  
        } else {
          // console.log('this is called if we are clicking to find wells')
          //transform([-12746726.903598461, 7285710.65473805], 'EPSG:3857', 'EPSG:4326')
          let clickCoordinates = transform( evt.map.getCoordinateFromPixel(evt.pixel), 'EPSG:3857', 'EPSG:4326') 
          
          // FINDING ARROW CLICKED ITEMS
          axios.post(process.env.VUE_APP_EX_IP+'/api/get_by_click', clickCoordinates, {withCredentials: true}).then((data) => {   
            
            if(!data.data.length){
              this.flashMessage = {msg:'No Wells Found', type:'error'}
              this.showAlert = true
              // this.showAlert = false
              // setTimeout(() => {
              //   this.showAlert = false
              // }, 1000)
              
            }
            let myArray = []
            data.data.forEach(data =>{
              myArray.push(JSON.parse(data.geojson))
            })
            let gisObject = {
              'type': 'FeatureCollection',
              'crs': {
                'type': 'name',
                'properties': {
                  'name': 'EPSG:3857'
                }
              },
              'features': myArray
            }        
            this.coordinateObject = gisObject      

            if (data.data.authenticated === false){
              this.notauthed = true
            }
          }) 
        } 
      },
      //END OF MAPCLICK
      getPipelines: function() {
        this.pipelinesLoading = true;
        // let pObject = {}
        let myArray = []
        axios.get(process.env.VUE_APP_EX_IP+'/api/get_pipelines').then((data) =>{
          // console.log('xyxy data', data.data)
          if(data.data.length){
            data.data.forEach((data) => {
              this.pipelinesLoading = false;
              myArray.push(JSON.parse(data.geojson))

            })
            let pipelineObject = {
            'type': 'FeatureCollection',
            'crs': {
              'type': 'name',
              'properties': {
                'name': 'EPSG:3857_pipelines'
              }
            },
            'features': myArray
            }        

            // let pipelineSource = new VectorSource({
            //   features: (new GeoJSON()).readFeatures(pipelineObject),

            // })    
            // let pipelineLayer = new VectorLayer({
            //   'name' : 'pipeliness',
            //   'visible' : true,
            //   source: pipelineSource,
            //   zIndex:90,    
            // });      
            this.pipelineLayer = pipelineObject

          } else {
            // !!! TODO SWITCH FIRES EACH AND EVERY TIME , WHETHER ITS ON OR OFF
            // DISABLED BY SETTING PIPELINELAYER TO {}
            this.pipelineLayer = {}
            this.pipelinesLoading = false;
            this.showAlert = true;
            this.flashMessage = {msg: 'No Pipelines Found', type: 'error'}
            // alertingg

          }


        })
      },     

      // this is the pipeline styles
      pipelineStyleFunction: (feature, resolution) => {
        var size = feature.get('OUT_DIAMET');
        if (size == "114.3"){ // 4"
        feature.setStyle(new Style({
          stroke: new Stroke({
            color: 'rgba(0,212,240,0.5)',
            width: 2.5,
            lineJoin: 'round',
            lineCap: 'round'
            //crossOrigin: 'anonymous',
            //src: 'data/square.svg'
          })
        })); 
          // return(four);
        } else if (size == "168.3"){ // 6"
        feature.setStyle(new Style({
          stroke: new Stroke({
            color: 'rgba(221,146,7,0.5)',
            width: 2.5
            //crossOrigin: 'anonymous',
            //src: 'data/square.svg'
          })
        })); 

          //return(four);
        } else if (size == "219.1"){ // 8"
          //return(four);
        feature.setStyle(new Style({
          stroke: new Stroke({
            color: 'rgba(0,109,45,0.5)',
            width: 2.5
            //crossOrigin: 'anonymous',
            //src: 'data/square.svg'
          })
        }));           
        } else if (size == "273.1"){ // 10"
          //return(four);
        feature.setStyle(new Style({
          stroke: new Stroke({
            color: 'rgba(0,252,105,0.5)',
            width: 2.5
            //crossOrigin: 'anonymous',
            //src: 'data/square.svg'
          })
        }));           
        } else
        feature.setStyle(new Style({
          stroke: new Stroke({
            color: 'rgba(109,23,51,0.5)',
            width: 2.5
            //crossOrigin: 'anonymous',
            //src: 'data/square.svg'
          })
        }));         
      },        

      // pinDrop: function(){
      //   this.pins.forEach((pin,index) => {
      //     this.pinArray.push(new Feature({
      //         geometry: new Point(
      //           // fromLonLat([-116.905796,53.905685]),
      //           // fromLonLat(this.test[index]),
      //           // undefiend error?
      //           fromLonLat(pin)   
      //         ),
      //     }));
      //   })
      // },
      //!!!visible
      // showPipelines: function (){
      //   this.pipelines.setVisible(!this.pipelines.getVisible())
      // },

    },
    beforeCreate(){
      if (!localStorage.userInfo){
        this.$router.push('/')
      }      
    },
    created(){
    },
    mounted() {
      // !!! SEARCHED FOR ITEMS
      if(this.$route.params.pins){
        let myArray = []
        let hiddenArray = []
        this.zoom = this.$route.params.zoom
        this.center = JSON.parse(this.$route.params.pins[0].geojson).geometry.coordinates[0]
        this.$route.params.pins.forEach(data =>{
          if(data.operator){
            myArray.push(JSON.parse(data.geojson))
          }
          else {
            hiddenArray.push(JSON.parse(data.geojson))
          }
          
        })

        let gisObject = {
          'type': 'FeatureCollection',
          'crs': {
            'type': 'name',
            'properties': {
              'name': 'EPSG:3857'
            }
          },
          'features': myArray
        }        

        let hiddenGisObject = {
          'type': 'FeatureCollection',
          'crs': {
            'type': 'name',
            'properties': {
              'name': 'EPSG:3857'
            }
          },
          'features': hiddenArray
        }  

        this.pinObject = gisObject     
        this.hiddenPinObject = hiddenGisObject 
      }

    }, //mounted ends here
    beforeDestroy(){
      clearInterval(this.interval);
    },

  } // script ends here
</script>

<style>

.main_map{
  /* added min- */
  min-height: calc(100vh - 54px);
  /* added to fix map on ios */
  /* min-height: calc(-webkit-fill-available - 54px); */

  height: 80px;
  width: 100%;
  border: 1px solid #ccc;
  /* ccc */
  /* padding: 0px; */
  /* margin: 0px; */
  position:absolute;
  /* min-height: calc(100vh);  */
  /* min-heightt: -webkit-fill-available;" */
}
.default_cursor{
  cursor: default;
  
}

.default_cursor:hover{
  /* color:red; */
  color: #37474f;
}



/* .legend{
  height: 22px;
  width: 22px;
  outline: 1px solid grey;
} */

.ol_box{
  color: white;
  /* color: rgb(144, 223, 144); */
  padding: 4px;
  font-size: 12px;
   /* z-index: 999;  */
   border-width: 1px; 
   border-radius: 5px;
   border-color: rgba(0, 60, 136);
   background-color:rgba(0,60,136,0.5);
   border-style: solid; 
}

/* i BUTTON DROP DOWN */
#mapcard2{
  position: absolute;
  top: 36px;
  right: -38px;
  z-index:1; 
  height: 250px;
  opacity: 0.8;
}


/* MAP i BUTTON */
.mapinfo {
  top: 7px;
  right: 10px;
}

#coordinates {
  z-index: 1;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  background: rgba(0,60,136,0.7);
  color: white;
  border: 0;
  transition: opacity 100ms ease-in;
}
/* popup */
#well_popup {
  /* opacity: 0.5; */
  text-align: left;
  background: rgba(0,60,136,0.5);
  color: white;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgb(0, 60, 136);
  /* background-color: rgba(255, 255, 255, 0.9); */
  padding: 4px;
  width: auto;
  height: auto;
  visibility: hidden;
}

.map_alert{
  z-index: 1;
  /* color: white;
  background-color: rgb(243, 16, 16); */
  position: absolute;
  top: 10px;
  left: 51%;
  /* 220 */
  width: max-content;
  /* min-width: 205px;
  max-width: 405px; */
  transform: translate(-50%, 0);

}

</style>